import React, {useEffect} from "react";
import {Box, Button, Stack} from "@mui/material";
import {useForm} from "react-hook-form";
import dayjs from "dayjs";
import TickBoxGroup from "../../BaseComponents/tickBoxGroup";
import NumberTextField from "../../BaseComponents/numberTextField";
import SingleValueAutoComplete from "../../BaseComponents/singleValueAutoComplete";
import DatePicker from "../../BaseComponents/datePicker";
import TimePickerForm from "../../BaseComponents/timePicker";
import TextField from "../../BaseComponents/textField";
import {CarRentalFormInputs} from "../../FormInputs/carRentalFormInputs";
import {Voucher} from "../../VoucherGeneration/voucher";
import {CarRentalVoucher} from "../../VoucherGeneration/carRentalVoucher";
import {getClientNames, postClientIfNew} from "../../../Services/DatabaseCommunicator/client";
import DatabaseOptionsAutoComplete from "../../BaseComponents/databaseOptionsAutoComplete";
import {ServiceProvider} from "../../VoucherGeneration/voucherGeneratorUtils";
import {getCarRentalServiceProvider} from "../../../Services/DatabaseCommunicator/carRentalServiceProvider";

export const CarRentalVoucherForm = () => {

    const {register, handleSubmit, control, watch, formState: {errors}} = useForm<CarRentalFormInputs>({
        criteriaMode: "all",
        defaultValues: {
            language: "French",
            voucherNumber: "0",
            suggestions: "",
            carRentalServiceProvider : ""
        }
    });
    const [clientNames, setClientNames] = React.useState<string[]>([]);

    useEffect(() => {
        const updateClientNames = async () => {
            setClientNames(await getClientNames());
        }
        updateClientNames();
    }, [])


    const onSubmit = async (data: CarRentalFormInputs) => {
        const serviceProvider: ServiceProvider = await getCarRentalServiceProvider(data.carRentalServiceProvider);

        let voucher: Voucher = new CarRentalVoucher(data.language, data, serviceProvider);

        voucher.generate();

        const newClientIsPosted = await postClientIfNew(data.clientName, clientNames);
        if (newClientIsPosted)
            setClientNames(clientNames => [...clientNames, data.clientName]);
    };
    const handleError = () => {
        console.log("errors: ", errors);
    };

    return (
        <div className={"carRentalForm"}>
            <Box
                style={{
                    alignItems: "center",
                }}
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} onError={handleError}>
                    <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <h2>Car rental Voucher</h2>
                        <TickBoxGroup options={["French", "English"]} name={"language"} control={control}
                                      register={register} errors={errors}/>
                        <NumberTextField name={"voucherNumber"} label={"Voucher Number"} register={register}
                                         errors={errors}/>
                        <Stack direction={"row"} alignItems={"baseline"} justifyContent={"flex-start"}>
                            <Stack direction={"column"} alignItems={"baseline"} justifyContent={"flex-start"}>
                                <SingleValueAutoComplete options={["Mr", "Mrs"]} name={"clientTitle"}
                                                         label={"Client Title"} control={control} register={register}
                                                         errors={errors}/>
                            </Stack>
                            <SingleValueAutoComplete options={clientNames} label={"Client Name"} freeSolo={true}
                                                     name={"clientName"} control={control} register={register}
                                                     errors={errors}/>
                        </Stack>

                        <NumberTextField name={"nbPax"} label={"Pax Number"} register={register} errors={errors}/>
                        <DatabaseOptionsAutoComplete query={"CarRentalServiceProvider"} freeSolo={false} name={"carRentalServiceProvider"}
                                                     label={"Car Rental Provider"} control={control} register={register}
                                                     errors={errors}/>

                        <DatePicker name={"startDate"} label={"Rental Date"} defaultValue={dayjs()} control={control}
                                    register={register} errors={errors}/>
                        <TimePickerForm name={"startTime"} label={"At"} defaultValue={dayjs()} control={control}
                                        register={register} errors={errors}/>

                        <TextField control={control} label={"Address"} name={"startAddress"} register={register}
                                   errors={errors}/>

                        <DatePicker name={"endDate"} label={"End Date"} defaultValue={dayjs()} rules={{
                            validate: () => {
                                return (watch().endDate?.isAfter(watch().startDate)) || "Date must be after rental date.";
                            }
                        }} control={control} register={register} errors={errors}/>
                        <TimePickerForm name={"endTime"} label={"At"} defaultValue={dayjs()} control={control}
                                        register={register} errors={errors}/>

                        <TextField control={control} label={"Address"} name={"endAddress"} register={register}
                                   errors={errors}/>
                        <TextField control={control} name={"suggestions"}
                                   label={"Suggestions"} register={register}
                                   errors={errors} multiline={true}
                                   isRequired={false}/>

                        <Button variant={"outlined"} type="submit">Generate</Button>

                    </Stack>
                </form>
            </Box>
        </div>
    )

}