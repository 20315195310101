import {Voucher} from "./voucher";
import {TourFormInputs} from "../FormInputs/tourFormInputs";
import {
    generateClientBlock,
    generateContact,
    generateHeaderBlock,
    generateServiceProvider,
    initializeAndSetUpDoc,
    ServiceProvider
} from "./voucherGeneratorUtils";
import {Dayjs} from "dayjs";
import {DATE_FORMAT, FILENAME_VOUCHER, FONT, FONT_BOLD, FONT_STYLE, TIME_FORMAT} from "./constantsVoucher";

const onurContact = "Onur : +90 530 546 87 91"
const enderContact = "Ender : +90 530 340 32 59"

export class TourVoucher extends Voucher {

    inputs: TourFormInputs;

    serviceProvider: ServiceProvider;

    constructor(language: string, inputs: TourFormInputs, serviceProvider: ServiceProvider) {
        super(language);
        this.inputs = inputs;
        this.serviceProvider = serviceProvider;
    }

    generate(): void {
        this.doc = initializeAndSetUpDoc(this.inputs.voucherNumber, this.inputs.language);
        generateHeaderBlock(this.doc);
        this.generateClientAndContactBlock();
        this.generateTourBlock();
        generateContact(this.doc, 20, 190, false);
        this.doc.save(`${FILENAME_VOUCHER} ${this.inputs.voucherNumber}`);
    }

    generateClientAndContactBlock() {
        generateClientBlock(this.doc, this.inputs.clientTitle, this.inputs.clientName, this.inputs.nbPax, 7);
        generateServiceProvider(this.doc, this.serviceProvider, 85);
    }

    generateTourBlock() {
        this.doc.rect(15, 110, this.doc.internal.pageSize.getWidth() - 30, 71)
        this.doc.line(55, 110, 55, 181);

        this.doc.setFont(FONT_BOLD);

        this.doc.text(`${this.dict.dates}${this.dict.twoPoints}`, 17, 116);

        this.verifyTour(this.inputs.tour1, this.inputs.date1, 17, 125);
        this.verifyTour(this.inputs.tour2, this.inputs.date2, 17, 135);
        this.verifyTour(this.inputs.tour3, this.inputs.date3, 17, 145);
        this.verifyTour(this.inputs.tour4, this.inputs.date4, 17, 155);

        this.doc.setFont(FONT_BOLD);
        this.doc.setTextColor(0, 0, 255);
        //this.doc.text("RDV à votre hôtel à l'heure convenue.\nComprend le guide, le transport, les\nentrées des sites et le déjeuner", 61, 167);
        this.doc.text(this.inputs.suggestions, 61, 165, {maxWidth: 70})
        this.doc.setFont(FONT, FONT_STYLE);
        this.doc.setTextColor(0, 0, 0);

    }

    verifyTour(tour: string, date: Dayjs | null, x: number, y: number): void {
        if (tour === undefined || tour === "" || tour === null)
            return;

        this.doc.setFont(FONT_BOLD);
        this.doc.text(`${date?.format(DATE_FORMAT)} ${this.dict.at} ${date?.format(TIME_FORMAT)} `, x, y);

        this.doc.setFont(FONT, FONT_STYLE);
        this.doc.text(tour, 61, y, {maxWidth: 70});
    }
}