import jsPDF from "jspdf";
import frData from "../../Dictionnaries/fr.json";
import enData from "../../Dictionnaries/en.json";
import {Dayjs} from "dayjs";
import {FILENAME_VOUCHER, FONT, FONT_BOLD, FONT_STYLE} from "./constantsVoucher";

export type Dict = typeof frData | typeof enData;

let dict: Dict;

export type ServiceProvider = {
    name: string,
    address: string,
    contactName: string,
    phone: string
}

export function addAirportImage(doc: jsPDF, airport: string) {
    if (airport !== "Istanbul Airport" && airport !== "Sabiha Gokçen Airport")
        return;

    doc.addPage();
    let airportImg = new Image()
    airportImg.src = airport === "Istanbul Airport" ? 'Istanbul.png' : 'SabihaGokçen.png';

    doc.addImage(airportImg, "png", 10, 5, 130, 194);
    if (airport === "Istanbul Airport")
        return;
    doc.addPage();
    airportImg.src = 'SabihaGokçen2.png';
    doc.addImage(airportImg, "png", 15, 20, 116, 139);
}

export function generateHeaderBlock(doc: jsPDF): void {
    doc.rect(15, 15, doc.internal.pageSize.getWidth() - 30, 35);
    doc.line(55, 15, 55, 50);

    doc.text(`Kutlugün Sokak 37\nSultanahmet\nIstanbul 34122,\n${dict.turkey}\nTel${dict.twoPoints} +90 212 518 22 82`, 60, 21);
    doc.setTextColor(0, 112, 192);
    doc.text("www.kirkit.com\ninfo@kirkit.com", 60, 44);
    let logo = new Image()
    logo.src = 'kirkitlogo.png'
    doc.addImage(logo, "png", 20, 25, 30, 12);
    doc.setTextColor(0, 0, 0);

}

export function generateContact(doc: jsPDF, x: number, y: number, displayPayedBy: boolean = true) {
    doc.setFont(FONT_BOLD);
    doc.text(`${dict.inCaseOfNeed}${dict.twoPoints}\nOnur : +90 530 546 87 91\nEnder: +90 530 340 32 59`, x, y);
    doc.setFont(FONT, FONT_STYLE);
    if (displayPayedBy)
        doc.text(`${dict.servicePaidBy}${dict.twoPoints} Kirkit Voyage`, x, y + 17);
}

export function getStringFromArray(array: string[]): string {
    let res: string = "";
    array.forEach(element => res += element + ", ");

    return res.slice(0, res.length - 2);
}

export function getDayDifference(arrivalDate: Dayjs | null, departureDate: Dayjs | null) {
    if (arrivalDate && departureDate) {
        return Math.ceil(departureDate.diff(arrivalDate, 'day', true));
    } else {
        return null;
    }
}

export function initializeAndSetUpDoc(voucherNumber: string, language: string): jsPDF {
    language === "French" ? dict = frData : dict = enData;

    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a5',
    });
    doc.setProperties({
        title: `${FILENAME_VOUCHER} ${voucherNumber}`
    });
    doc.setFont(FONT);
    doc.text(`${dict.voucher} ${voucherNumber}`, 74, 10, {align: "center"});
    doc.setFontSize(11);

    doc.setLineWidth(0.6)
    doc.setDrawColor(31, 73, 125)
    return doc;
}

export function generateClientBlock(doc: jsPDF, clientTitle: string, clientName: string, nbPax: string, yOffset: number = 0) {
    doc.rect(15, 57, doc.internal.pageSize.getWidth() - 30, 40 + yOffset);
    doc.line(55, 57, 55, 97 + yOffset);

    doc.setTextColor(0, 0, 0);
    doc.setFont(FONT_BOLD);
    doc.text(`${dict.client}${dict.twoPoints}\n\n\n${dict.participants}${dict.twoPoints}\n\n\n`, 20, 63)
    doc.text(`${clientTitle}. ${clientName}\n\n\n${nbPax}`, 60, 63);

    doc.setFont(FONT, FONT_STYLE);
}

export function generateServiceProvider(doc: jsPDF, serviceProvider: ServiceProvider, yPos: number = 0) {
    doc.setFont(FONT_BOLD);
    doc.text(`${dict.serviceBy}${dict.twoPoints}`, 20, yPos);
    doc.text(serviceProvider.name.toUpperCase(), 60, yPos);

    yPos += 5;
    let yOffset = 0;

    doc.setFont(FONT, FONT_STYLE);

    if (serviceProvider.address !== undefined) {
        doc.text(`${serviceProvider.address}`, 60, yPos, {maxWidth: 70});
        yPos += 5;
        if (serviceProvider.address.length > 35)
            yOffset += 4;
    }

    if (serviceProvider.contactName !== undefined) {
        doc.text(`${serviceProvider.contactName}`, 60, yPos + yOffset, {maxWidth: 70})
        yPos += 5;
        if (serviceProvider.contactName.length > 35)
            yOffset += 4;
    }

    doc.setFont(FONT_BOLD);
    doc.text(`Tel${dict.twoPoints} `, 60, yPos + yOffset);
    doc.setFont(FONT, FONT_STYLE);
    doc.text(`${formatPhoneNumber(serviceProvider.phone)}`, 69, yPos + yOffset);
}

export function formatPhoneNumber(phoneNumber: string): string {
    // Define the regex pattern for phone number validation
    const spacePhoneRegex = /^\+90\s?\d{3}\s?\d{3}\s?\d{2}\s?\d{2}$/;
    const noSpacePhoneRegex = /^\+90\d{3}\d{3}\d{2}\d{2}$/;

    const withSpacePattern = /^(\+\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/;
    // Check if the phone number matches the regex pattern

    if (phoneNumber.match(noSpacePhoneRegex)) {
        return phoneNumber.replace(withSpacePattern, '$1 $2 $3 $4 $5');
    } else if (phoneNumber.match(spacePhoneRegex))
        return phoneNumber;
    return phoneNumber
}

export function isNullOrEmptyOrUndefined(str : string) : boolean
{
    return str === undefined || str === null || str === "";
}
