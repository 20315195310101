import {Body, get, post, put, remove} from "../Api/Provider";
import {AIRPORT_URL} from "./constants";

export async function getAirports() {
    let airports: { [key: string | number]: any }[];
    airports = await get(AIRPORT_URL).then(async res => {
        return await res?.json();
    });
    return airports;
}

export async function getAirport(name: string) {
    return await get(`${AIRPORT_URL}/name/${name}`).then(async res => {
        return await res?.json()
    })
}

export async function onAddAirport(name: string, information: string): Promise<boolean> {
    const body = {
        name: name,
        informationText: information
    };

    try {
        const res = await post(AIRPORT_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}

export async function onPutAirport(id: number | undefined, name: string, information: string): Promise<boolean> {
    if (id === undefined)
        return onAddAirport(name, information);

    let body: Body = {
        id: id,
        name: name,
        informationText: information,
    }

    try {
        const res = await put(AIRPORT_URL, body);
        if (!res) {
            return false;
        }
        return res.status === 204;

    } catch (e) {
        return false;
    }
}

export async function onDeleteAirport(name: string): Promise<boolean> {

    try {
        const res = await remove(`${AIRPORT_URL}/name/${name}`);
        if (!res) {
            return false;
        }
        return res.status === 200 || res.status === 204;
    } catch (e) {
        return false;
    }

}