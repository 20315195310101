import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React from "react";
import {Control, Controller, FieldErrors, FieldValues, Path, UseFormRegister} from "react-hook-form";

interface Props<T extends FieldValues> {

    options: string[];
    name: Path<T>;
    control: Control<T>;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;

}

function TickBoxGroup<T extends FieldValues>(props: Props<T>) {
    return (
        <FormControl component="fieldset">
            <Controller
                rules={{required: true}}
                control={props.control}
                name={props.name}

                render={({field}) => {
                    return (
                        <RadioGroup
                            {...field}
                            defaultValue={props.options[0]}
                        >
                            {props.options.map(value => <FormControlLabel key={value} value={value} control={<Radio/>}
                                                                          label={value}/>)}
                        </RadioGroup>)
                }}
            />
        </FormControl>
    )
}

export default TickBoxGroup;
