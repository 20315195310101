import {Body, get, post, put, remove} from "../Api/Provider";
import {TRANSFER_SERVICE_PROVIDER_URL} from "./constants";

export async function getTransferServiceProviders() {
    let transferServiceProvider: { [key: string | number]: any }[];
    transferServiceProvider = await get(TRANSFER_SERVICE_PROVIDER_URL).then(async res => {
        return await res?.json();
    });
    return transferServiceProvider;
}

export async function getTransferServiceProvider(name: string) {
    return await get(`${TRANSFER_SERVICE_PROVIDER_URL}/name/${name}`).then(async res => {
        return await res?.json()
    })
}

export async function onAddTransferServiceProvider(name: string, contactName: string, phone: string): Promise<boolean> {
    const body = {
        name: name,
        contactName: contactName,
        phone: phone,
    };

    try {
        const res = await post(TRANSFER_SERVICE_PROVIDER_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}

export async function onPutTransferServiceProvider(id: number | undefined, name: string, contactName: string, phone: string): Promise<boolean> {
    if (id === undefined)
        return onAddTransferServiceProvider(name, contactName, phone);

    let body: Body = {
        id: id,
        name: name,
        contactName: contactName,
        phone: phone,
    }

    try {
        const res = await put(TRANSFER_SERVICE_PROVIDER_URL, body);
        if (!res) {
            return false;
        }
        return res.status === 204;

    } catch (e) {
        return false;
    }
}

export async function onDeleteTransferServiceProvider(name: string): Promise<boolean> {

    try {
        const res = await remove(`${TRANSFER_SERVICE_PROVIDER_URL}/name/${name}`);
        if (!res) {
            return false;
        }
        return res.status === 200 || res.status === 204;
    } catch (e) {
        return false;
    }

}