import {handleError, handleResponse} from "./Response";

const API_URL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_PATH}`;

export interface Body {
    id: number | undefined

    [key: string]: any;
}

const post = async (path: String, body: object) => {
    return await fetch(`${API_URL}${path}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(handleResponse)
        .catch(handleError)
}

const get = async (path: String) => {
    return await fetch(`${API_URL}${path}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    }).then(handleResponse)
        .catch(e => {
            console.log("ERROR WAS:", e.body)
        });
}

const put = async (path: String, body: Body) => {
    return await fetch(`${API_URL}${path}/${body.id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(handleResponse)
        .catch(handleError)
}
const remove = async (path: String) => {
    return await fetch(`${API_URL}${path}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        }
    }).then(handleResponse)
}

export {post, get, put, remove};
