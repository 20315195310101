import {
    Control,
    Controller,
    FieldError,
    FieldErrors,
    FieldValues,
    Path,
    RegisterOptions,
    UseFormRegister
} from "react-hook-form";
import {TextField} from "@mui/material";
import React from "react";

interface Props<T extends FieldValues> {

    control: Control<T>;

    name: Path<T>;
    label: string;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;

    multiline?: boolean;

    isRequired?: boolean;

    rules?: RegisterOptions<T>;
}


const Textfield = <T extends FieldValues>(props: Props<T>) => {
    const {isRequired = true, ...rest} = props;
    const defaultRules = {
        required: isRequired ? {
            value: true,
            message: "This field is required."
        } : false,
        ...props.rules
    };

    return (

        <Controller
            control={props.control}
            name={props.name}
            rules={defaultRules}
            render={({
                         field: {onChange, value},
                         fieldState: {error},
                     }) => (
                <TextField
                    value={value}
                    InputLabelProps={{shrink: !!value}}
                    multiline={props.multiline}
                    onChange={({target: {value}}) => {
                        onChange(value)
                        //if (props?.setValue) props.setValue(props.name, value)
                    }}
                    margin="normal"
                    variant="outlined"
                    error={!!props.errors[props.name]}
                    helperText={(props.errors[props.name] as FieldError)?.message ?? ""}
                    label={props.label}

                />
            )}
        />

    )
}

export default Textfield;