import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import SingleValueAutoComplete from "../../BaseComponents/singleValueAutoComplete";
import TextField from "../../BaseComponents/textField";
import {getRooms, onDeleteRoom, onPutRoom} from "../../../Services/DatabaseCommunicator/room";

interface Inputs {

    options: string[];

    id: number | undefined;
    name: string;

}

const RoomModifierForm = () => {
    const {register, setValue, handleSubmit, control, formState: {errors}} = useForm<Inputs>({
        criteriaMode: "all",
        defaultValues: {
            id: undefined,
            name: ""
        }
    });

    const [optionNames, setOptionNames] = React.useState<string[]>([""]);
    const [options, setOptions] = React.useState<{ [key: string | number]: any }[]>([]);
    const [message, setMessage] = React.useState<string>("");

    const fetchRooms = async () => {
        const rooms = await getRooms();
        setOptions(rooms);
        const optionNames = rooms.map(room => room?.name).filter(name => name !== undefined) as string[];
        setOptionNames(optionNames);
    }

    useEffect(() => {
        fetchRooms();
    }, [])

    const onSearchOptionSelected = (optionName: string) => {
        const option = options.find((obj: { [key: string]: any }) => obj.name === optionName);
        setValue("id", undefined)

        if (!option)
            return;

        setValue("id", option.id)
        setValue("name", option.name);
    }
    const onSubmit = async (data: Inputs) => {
        const RoomWasPut = await onPutRoom(data.id, data.name);
        if (RoomWasPut)
            setMessage("Successfully updated data.");
        else
            setMessage("Could not update data.");
        clearFormValues();
        await fetchRooms();
    }
    const onClear = () => {
        setMessage("");
        clearFormValues();
    }

    const clearFormValues = () => {
        setValue("id", undefined);
        setValue("name", "");
    }
    const onDelete = async (data: Inputs) => {
        const isDeleted = await onDeleteRoom(data.name);
        if (!isDeleted) {
            setMessage("Could not delete data.");
            return;
        }

        clearFormValues();
        setMessage("Successfully deleted data.");
        await fetchRooms();
    }

    return (
        <div className={"RoomModifierForm"}>
            <Box
                style={{
                    alignItems: "center",
                }}
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"flex-start"}>
                            <SingleValueAutoComplete options={optionNames} freeSolo={true} name={"name"}
                                                     label={"Room Name"}
                                                     control={control} register={register} errors={errors}
                                                     required={true}
                                                     onOptionSelected={onSearchOptionSelected}/>
                            <Button variant={"outlined"} onClick={onClear}>Clear</Button>

                        </Stack>

                        <TextField control={control} name={"name"} label={"Room name"} register={register}
                                   errors={errors}/>
                        <Stack direction={"row"} alignItems={"baseline"} spacing={2} justifyContent={"flex-start"}>
                            <Button variant={"outlined"} onClick={handleSubmit(onSubmit)}>Save</Button>
                            <Button variant={"outlined"} onClick={handleSubmit(onDelete)}>Delete</Button>
                        </Stack>
                        <Typography>{message}</Typography>
                    </Stack>
                </form>
            </Box>
        </div>
    )
}

export default RoomModifierForm;