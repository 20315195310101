import React, {useEffect} from 'react';
import './App.css';
import Home from "./Components/home";
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme";
import HotelVoucherForm from "./Components/Forms/VoucherForms/hotelVoucherForm";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import TransferVoucherForm from "./Components/Forms/VoucherForms/transferVoucherForm";
import {CarRentalVoucherForm} from "./Components/Forms/VoucherForms/carRentalVoucherForm";
import TourVoucherForm from "./Components/Forms/VoucherForms/tourVoucherForm";
import HotelModifierForm from "./Components/Forms/DatabaseForms/HotelModifierForm";
import RoomModifierForm from "./Components/Forms/DatabaseForms/RoomModifierForm";
import BasisModifierForm from "./Components/Forms/DatabaseForms/BasisModifierForm";
import TourModifierForm from "./Components/Forms/DatabaseForms/TourModifierForm";
import ClientModifierForm from "./Components/Forms/DatabaseForms/ClientModifierForm";
import AirportModifierForm from "./Components/Forms/DatabaseForms/AirportModifierForm";
import TransferServiceProviderModifierForm from "./Components/Forms/DatabaseForms/TransferServiceProviderModifierForm";
import TourServiceProviderModifierForm from "./Components/Forms/DatabaseForms/TourServiceProviderModifierForm";
import CarRentalServiceProviderModifierForm
    from "./Components/Forms/DatabaseForms/CarRentalServiceProviderModifierForm";
import Keycloak from "keycloak-js";

function App() {

    const provider = new Keycloak({
        url: "https://auth.kirkit-vouchers.com",
        realm: "kirkit-vouchers",
        clientId : "frontend"
    });

    useEffect(() => {
        const init = async () =>
        {
            await provider.init({
                flow: "standard"
            })

            if(!provider.authenticated)
                await provider.login();

             await provider.loadUserInfo();

             console.log(provider.userInfo);


            console.log(provider.token);
        }

        init()
    }, [])

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <header>
                        <Link to="/">
                            <h1>Kirkit vouchers</h1>
                        </Link>
                    </header>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/hotel" element={<HotelVoucherForm/>}/>
                        <Route path="/transfer" element={<TransferVoucherForm/>}/>
                        <Route path="/carRental" element={<CarRentalVoucherForm/>}/>
                        <Route path="/tour" element={<TourVoucherForm/>}/>

                        <Route path="/hotelModifier" element={<HotelModifierForm/>}/>
                        <Route path="/roomModifier" element={<RoomModifierForm/>}/>
                        <Route path="/basisModifier" element={<BasisModifierForm/>}/>
                        <Route path="/tourModifier" element={<TourModifierForm/>}/>
                        <Route path="/clientModifier" element={<ClientModifierForm/>}/>
                        <Route path="/airportModifier" element={<AirportModifierForm/>}/>
                        <Route path="/transferServiceProviderModifier"
                               element={<TransferServiceProviderModifierForm/>}/>
                        <Route path="/tourServiceProviderModifier" element={<TourServiceProviderModifierForm/>}/>
                        <Route path="/carRentalServiceProviderModifier"
                               element={<CarRentalServiceProviderModifierForm/>}/>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
