import {Body, get, post, put, remove} from "../Api/Provider";
import {CLIENT_URL} from "./constants";

export async function getClients() {
    let client: { [key: string | number]: any }[];
    client = await get(CLIENT_URL).then(async res => {
        return await res?.json();
    });
    return client;
}

export async function getClientNames(): Promise<Array<string>> {
    let clientNames: Array<string>;
    clientNames = await get(CLIENT_URL).then(async res => {
        let clientNames: Array<string> = [];
        if (res?.ok) {
            const data = await res.json();
            await data.map((element: { name: string }) => clientNames.push(element.name));

        }
        return clientNames;
    })
    return clientNames;
}

export async function postClientIfNew(clientName: string, clientNames: Array<string>): Promise<boolean> {
    if (clientNames.includes(clientName))
        return false;

    const body = {
        name: clientName,
    };

    try {
        const res = await post(CLIENT_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}

export async function onAddClient(name: string): Promise<boolean> {
    const body = {
        name: name,
    };

    try {
        const res = await post(CLIENT_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}

export async function onPutClient(id: number | undefined, name: string): Promise<boolean> {
    if (id === undefined)
        return onAddClient(name);

    let body: Body = {
        id: id,
        name: name,
    }

    try {
        const res = await put(CLIENT_URL, body);
        if (!res) {
            return false;
        }
        return res.status === 204;

    } catch (e) {
        return false;
    }
}

export async function onDeleteClient(name: string): Promise<boolean> {
    try {
        const res = await remove(`${CLIENT_URL}/name/${name}`);
        if (!res) {
            return false;
        }
        return res.status === 200 || res.status === 204;
    } catch (e) {
        return false;
    }

}