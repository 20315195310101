import {Body, get, post, put, remove} from "../Api/Provider";
import {BASIS_URL} from "./constants";


export async function getBasis() {
    let basis: { [key: string | number]: any }[];
    basis = await get(BASIS_URL).then(async res => {
        return await res?.json();
    });
    return basis;
}

export async function onAddBasis(name: string): Promise<boolean> {
    const body = {
        name: name,
    };

    try {
        const res = await post(BASIS_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}

export async function onPutBasis(id: number | undefined, name: string): Promise<boolean> {
    if (id === undefined)
        return onAddBasis(name);

    let body: Body = {
        id: id,
        name: name,
    }

    try {
        const res = await put(BASIS_URL, body);
        if (!res) {
            return false;
        }
        return res.status === 204;

    } catch (e) {
        return false;
    }
}

export async function onDeleteBasis(name: string): Promise<boolean> {
    try {
        const res = await remove(`${BASIS_URL}/name/${name}`);
        if (!res) {
            return false;
        }
        return res.status === 200 || res.status === 204;
    } catch (e) {
        return false;
    }

}