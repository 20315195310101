import {Autocomplete, TextField} from "@mui/material";
import React from "react";
import {Control, Controller, FieldError, FieldErrors, FieldValues, Path, UseFormRegister} from "react-hook-form";


interface Props<T extends FieldValues> {
    name: Path<T>;
    label: string;
    control: Control<T>;
    register: UseFormRegister<T>;

    errors: FieldErrors<T>;
    options: string[];

    freeSolo?: boolean;

    required?: boolean;
    onOptionSelected?: (optionName: string) => void;

}

const SingleValueAutoComplete = <T extends FieldValues>(props: Props<T>) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            rules={{
                validate: (value: string) => {
                    return (props.required === false || (value !== null && value !== undefined && value !== "")) || "At least one must be selected.";
                }
            }}
            render={({field: {onChange, value}}) => (
                <Autocomplete
                    onChange={(event, item) => {
                        onChange(item);
                        if (props.onOptionSelected) {
                            if (item) {
                                props.onOptionSelected(item)
                            }
                        }
                    }}
                    freeSolo={props.freeSolo !== undefined ? props.freeSolo : false}
                    autoSelect
                    options={props.options}
                    renderInput={(params) => <TextField {...params}
                                                        error={!!props.errors[props.name]}
                                                        helperText={(props.errors[props.name] as FieldError)?.message ?? ""}
                                                        label={props.label}/>}
                />
            )}
        />

    )
}

export default SingleValueAutoComplete;