import {Autocomplete, TextField} from "@mui/material";
import React, {useEffect} from "react";
import {Control, Controller, FieldError, FieldErrors, FieldValues, Path, UseFormRegister} from "react-hook-form";
import {get} from "../../Services/Api/Provider";


interface Props<T extends FieldValues> {
    query: string;
    name: Path<T>;

    label: string;

    control: Control<T>;
    register: UseFormRegister<T>;

    errors: FieldErrors<T>;

    required?: boolean;

    freeSolo?: boolean;

    multiple?: boolean

    onOptionSelected?: (option: { [key: string]: any; }) => void;

}

const DatabaseOptionsAutoComplete = <T extends FieldValues>(props: Props<T>) => {

    const [optionNames, setOptionNames] = React.useState<string[]>([]);
    const [options, setOptions] = React.useState<{ [key: string | number]: any }[]>([]);


    useEffect(() => {
        get("/" + props.query).then(res => {
            if (res?.ok) {
                res.json().then((values: { name?: string }[]) => {
                    const optionNames = values.map(val => val?.name).filter(name => name !== undefined) as string[];

                    setOptionNames(optionNames);
                    setOptions(values);
                });
            }
        });
    }, []);

    return (
        <Controller
            control={props.control}
            name={props.name}
            rules={{
                validate: (value: string) => {
                    return (props.required === false || (value !== null && value !== undefined && value !== "")) || "At least one must be selected.";
                }
            }}
            render={({field: {onChange}}) => (
                <Autocomplete
                    multiple={props.multiple}
                    autoSelect={props.multiple}
                    onChange={(event, item) => {
                        onChange(item);
                        if (props.onOptionSelected) {
                            if (item) {

                                const myObject = options.find((obj: { [key: string]: any }) => obj.name === item);

                                if (myObject && myObject.name) {
                                    props.onOptionSelected(myObject);
                                }
                            }
                        }
                    }}
                    freeSolo={props.freeSolo}
                    options={optionNames}
                    isOptionEqualToValue={() => false}
                    getOptionLabel={(item) => (item ? item : "")}
                    renderInput={(params) => <TextField {...params}
                                                        error={!!props.errors[props.name]}
                                                        helperText={(props.errors[props.name] as FieldError)?.message ?? ""}
                                                        label={props.label}/>}
                />
            )}
        />

    )
}

export default DatabaseOptionsAutoComplete;