import {Voucher} from "./voucher";
import {CarRentalFormInputs} from "../FormInputs/carRentalFormInputs";
import {
    generateClientBlock,
    generateContact,
    generateHeaderBlock,
    initializeAndSetUpDoc, isNullOrEmptyOrUndefined, ServiceProvider
} from "./voucherGeneratorUtils";
import {DATE_FORMAT, FILENAME_VOUCHER, FONT, FONT_BOLD, FONT_STYLE, TIME_FORMAT} from "./constantsVoucher";


export class CarRentalVoucher extends Voucher {
    inputs: CarRentalFormInputs;

    serviceProvider: ServiceProvider;

    constructor(language: string, inputs: CarRentalFormInputs, serviceProvider: ServiceProvider) {
        super(language);
        this.inputs = inputs;
        this.serviceProvider = serviceProvider;
    }

    generate() {
        this.doc = initializeAndSetUpDoc(this.inputs.voucherNumber, this.inputs.language);
        generateHeaderBlock(this.doc);
        this.generateClientAndServiceBlock()
        this.generateTimeAndAddressBlock()
        generateContact(this.doc, 20, 177);
        this.doc.save(`${FILENAME_VOUCHER} ${this.inputs.voucherNumber}`);
    }

    generateClientAndServiceBlock() {
        generateClientBlock(this.doc, this.inputs.clientTitle, this.inputs.clientName, this.inputs.nbPax, 10);
        this.doc.setFont(FONT_BOLD);
        this.doc.text(`${this.dict.services}${this.dict.twoPoints}`, 20, 85);
        this.doc.text(this.serviceProvider.name, 60, 85);

        let yPos : number = 90
        let yOffset = 0;

        if(!isNullOrEmptyOrUndefined(this.serviceProvider.address))
        {
            this.doc.text(this.serviceProvider.address, 60,  yPos, {maxWidth: 70});
            yPos += 5;
            if (this.serviceProvider.address.length > 35)
                yOffset += 4;
        }

        if(!isNullOrEmptyOrUndefined(this.serviceProvider.contactName))
        {
            this.doc.text(this.serviceProvider.contactName, 60,  yPos + yOffset, {maxWidth: 70});
            yPos += 5;
            if (this.serviceProvider.contactName.length > 35)
                yOffset += 4;
        }

        this.doc.text(this.serviceProvider.phone, 60,  yPos + yOffset, {maxWidth: 70});

        this.doc.setFont(FONT, FONT_STYLE);
    }

    generateTimeAndAddressBlock() {
        this.doc.rect(15, 115, this.doc.internal.pageSize.getWidth() - 30, 45)
        this.doc.line(55, 115, 55, 160);

        this.doc.setFont(FONT_BOLD);
        this.doc.text(`Du${this.dict.twoPoints} ${this.inputs.startDate?.format(DATE_FORMAT)}\n\n${this.dict.at}${this.dict.twoPoints} ${this.inputs.endDate?.format(DATE_FORMAT)}`, 20, 121, {maxWidth: 70})

        this.doc.setFont(FONT, FONT_STYLE);
        this.doc.text(`${this.dict.at} ${this.inputs.startTime?.format(TIME_FORMAT)} ${this.dict.at.toLowerCase()} ${this.inputs.startAddress}\n\n${this.dict.at} ${this.inputs.endTime?.format(TIME_FORMAT)} ${this.dict.at.toLowerCase()} ${this.inputs.endAddress}`, 60, 121, {maxWidth: 70})
        this.doc.setTextColor(0, 0, 255);

        this.doc.setFont(FONT_BOLD);
        if (this.inputs.suggestions !== "") {
            this.doc.text(this.inputs.suggestions, 60, 141, {maxWidth: 70})
        } else {
            this.doc.text("Votre chauffeur vous attendra devant l'hôtel à l'heure convenue.", 60, 136, {maxWidth: 70})
        }
        this.doc.setTextColor(0, 0, 0);
    }

}