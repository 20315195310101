import {FieldError, FieldErrors, FieldValues, Path, UseFormRegister} from "react-hook-form";
import {TextField} from "@mui/material";
import React from "react";

interface Props<T extends FieldValues> {
    name: Path<T>;
    label: string;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
}


const NumberTextField = <T extends FieldValues>(props: Props<T>) => {
    return (
        <TextField id="outlined-required" label={props.label}
                   type={"number"} variant="outlined" {...props.register(props.name, {
            required: {
                value: true,
                message: "please enter a voucher number"
            },
            min: {
                value: 1,
                message: "Value must be positive."
            }
        })}
                   error={!!props.errors[props.name]}
                   helperText={(props.errors[props.name] as FieldError)?.message ?? ""}
                   inputProps={{min: 1}}
        />
    )
}

export default NumberTextField;