import {Body, get, post, put, remove} from "../Api/Provider";
import {CAR_RENTAL_SERVICE_PROVIDER_URL} from "./constants";

export async function getCarRentalServiceProviders() {
    let CarRentalServiceProvider: { [key: string | number]: any }[];
    CarRentalServiceProvider = await get(CAR_RENTAL_SERVICE_PROVIDER_URL).then(async res => {
        return await res?.json();
    });
    return CarRentalServiceProvider;
}

export async function getCarRentalServiceProvider(name: string) {
    return await get(`${CAR_RENTAL_SERVICE_PROVIDER_URL}/name/${name}`).then(async res => {
        return await res?.json()
    })
}

export async function onAddCarRentalServiceProvider(name: string, address: string, contactName: string, phone: string): Promise<boolean> {
    const body = {
        name: name,
        address: address,
        contactName: contactName,
        phone: phone,
    };

    try {
        const res = await post(CAR_RENTAL_SERVICE_PROVIDER_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}

export async function onPutCarRentalServiceProvider(id: number | undefined, name: string, address: string, contactName: string, phone: string): Promise<boolean> {
    if (id === undefined)
        return onAddCarRentalServiceProvider(name, address, contactName, phone);

    let body: Body = {
        id: id,
        name: name,
        address: address,
        contactName: contactName,
        phone: phone,
    }

    try {
        const res = await put(CAR_RENTAL_SERVICE_PROVIDER_URL, body);
        if (!res) {
            return false;
        }
        return res.status === 204;

    } catch (e) {
        return false;
    }
}

export async function onDeleteCarRentalServiceProvider(name: string): Promise<boolean> {

    try {
        const res = await remove(`${CAR_RENTAL_SERVICE_PROVIDER_URL}/name/${name}`);
        if (!res) {
            return false;
        }
        return res.status === 200 || res.status === 204;
    } catch (e) {
        return false;
    }

}