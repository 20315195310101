import {
    Control,
    Controller,
    FieldError,
    FieldErrors,
    FieldValues,
    Path,
    PathValue,
    RegisterOptions,
    UseFormRegister
} from "react-hook-form";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TextField} from "@mui/material";
import React from "react";

interface Props<T extends FieldValues> {

    name: Path<T>;

    label: string;
    defaultValue: PathValue<T, Path<T>> | undefined;
    control: Control<T>;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    rules?: RegisterOptions<T>;
}

const DatePicker = <T extends FieldValues>(props: Props<T>) => {

    return (
        <div>
            <Controller
                name={props.name}
                defaultValue={props.defaultValue}
                control={props.control}
                rules={props.rules}
                render={
                    ({field: {onChange, ...restField}}) =>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                label={props.label}
                                onChange={(event) => {
                                    onChange(event);
                                }}
                                renderInput={(params) => <TextField {...params}
                                                                    error={!!props.errors[props.name]}
                                                                    helperText={(props.errors[props.name] as FieldError)?.message ?? ""}/>}
                                {...restField}
                            />
                        </LocalizationProvider>
                }
            />
        </div>
    )
}

export default DatePicker;