import {useForm} from "react-hook-form";
import {Box, Button, Stack} from "@mui/material";
import TickBoxGroup from "../../BaseComponents/tickBoxGroup";
import NumberTextField from "../../BaseComponents/numberTextField";
import React, {useEffect} from "react";
import DatePicker from "../../BaseComponents/datePicker";
import dayjs from "dayjs";
import TextField from "../../BaseComponents/textField";
import TimePickerForm from "../../BaseComponents/timePicker";
import SingleValueAutoComplete from "../../BaseComponents/singleValueAutoComplete";
import DatabaseOptionsAutoComplete from "../../BaseComponents/databaseOptionsAutoComplete";
import ConditionalComponent from "../../BaseComponents/conditionalComponent";
import {Voucher} from "../../VoucherGeneration/voucher";
import {DepartureTransferVoucher} from "../../VoucherGeneration/departureTransferVoucher";
import {Airport, ArrivalTransferVoucher} from "../../VoucherGeneration/arrivalTransferVoucher";
import {TransferFormInputs} from "../../FormInputs/transferFormInputs";
import {getClientNames, postClientIfNew} from "../../../Services/DatabaseCommunicator/client";
import {getAirport} from "../../../Services/DatabaseCommunicator/airport";
import {ServiceProvider} from "../../VoucherGeneration/voucherGeneratorUtils";
import {getTransferServiceProvider} from "../../../Services/DatabaseCommunicator/transferServiceProvider";


const TransferVoucherForm = () => {
    const {register, handleSubmit, control, watch, formState: {errors}} = useForm<TransferFormInputs>({
        criteriaMode: "all",
        defaultValues: {
            language: "French",
            voucherNumber: "0",
            transferType: "Arrival",
            airport: "",
            date: dayjs(),
            transferServiceProvider: ""
        }
    });

    const [clientNames, setClientNames] = React.useState<string[]>([]);

    useEffect(() => {
        const updateClientNames = async () => {
            setClientNames(await getClientNames());
        }
        updateClientNames();
    }, [])

    const onSubmit = async (data: TransferFormInputs) => {
        let voucher: Voucher;

        const serviceProvider: ServiceProvider = await getTransferServiceProvider(data.transferServiceProvider);

        if (data.transferType === "Departure") {
            if (data.suggestions == undefined){
                data.suggestions = "";
            }
            voucher = new DepartureTransferVoucher(data.language, data, serviceProvider);
        } else {
            const airport: Airport = await getAirport(data.airport);

            voucher = new ArrivalTransferVoucher(data.language, data, airport, serviceProvider);
        }

        voucher.generate();
        const newClientIsPosted = await postClientIfNew(data.clientName, clientNames);
        if (newClientIsPosted)
            setClientNames(clientNames => [...clientNames, data.clientName]);
    }

    const handleError = () => {
        console.log("errors: ", errors);
    }
    return (
        <div className={"transferForm"}>
            <Box
                style={{
                    alignItems: "center",
                }}
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} onError={handleError}>
                    <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <h2>Transfer Voucher</h2>
                        <TickBoxGroup options={["French", "English"]} name={"language"} control={control}
                                      register={register} errors={errors}/>
                        <TickBoxGroup options={["Arrival", "Departure"]} name={"transferType"} control={control}
                                      register={register} errors={errors}/>
                        <NumberTextField name={"voucherNumber"} label={"Voucher Number"} register={register}
                                         errors={errors}/>
                        <Stack direction={"row"} alignItems={"baseline"} justifyContent={"flex-start"}>
                            <Stack direction={"column"} alignItems={"baseline"} justifyContent={"flex-start"}>
                                <SingleValueAutoComplete options={["Mr", "Mrs"]} name={"clientTitle"}
                                                         label={"Client Title"} control={control} register={register}
                                                         errors={errors}/>
                            </Stack>
                            <SingleValueAutoComplete options={clientNames} label={"Client Name"} freeSolo={true}
                                                     name={"clientName"} control={control} register={register}
                                                     errors={errors}/>
                        </Stack>
                        <NumberTextField name={"nbPax"} label={"Pax Number"} register={register} errors={errors}/>
                        <DatePicker name={"date"} label={"Transfer Date"} defaultValue={dayjs()} control={control}
                                    register={register} errors={errors}/>
                        <DatabaseOptionsAutoComplete query={"Airport"} freeSolo={false} name={"airport"}
                                                     label={"Airport Name"} control={control} register={register}
                                                     errors={errors}/>
                        <TextField control={control} label={"Flight Number"} name={"flightNumber"} register={register}
                                   errors={errors}/>
                        <TimePickerForm name={"flightTime"} label={"Flight time"} defaultValue={dayjs()}
                                        control={control} register={register} errors={errors}/>
                        <DatabaseOptionsAutoComplete query={"Hotel"} freeSolo={false} name={"hotelName"}
                                                     label={"Hotel Name"} control={control} register={register}
                                                     errors={errors}/>
                        <DatabaseOptionsAutoComplete query={"transferServiceProvider"} freeSolo={false}
                                                     name={"transferServiceProvider"} label={"Service Provider"}
                                                     control={control} register={register} errors={errors}/>
                        <ConditionalComponent isRender={watch().transferType === "Departure"}
                                              component={<TimePickerForm name={"departureTime"} label={"Departure Time"}
                                                                         defaultValue={dayjs()} control={control}
                                                                         register={register} errors={errors}/>}/>
                        <ConditionalComponent isRender={watch().transferType === "Departure"}
                                              component={<TextField control={control} name={"suggestions"}
                                                                    label={"Suggestions"} register={register}
                                                                    errors={errors} multiline={true}
                                                                    isRequired={false}/>}/>
                        <Button variant={"outlined"} type="submit">Generate</Button>
                    </Stack>
                </form>
            </Box>
        </div>
    )
}

export default TransferVoucherForm;