import jsPDF from "jspdf";
import frData from "../../Dictionnaries/fr.json";
import enData from "../../Dictionnaries/en.json";
import '../../trebuc-normal.js';
import '../../trebucBold-normal.js';

export type Dict = typeof frData | typeof enData;

export abstract class Voucher {
    doc: jsPDF;
    dict: Dict;

    protected constructor(language: string) {
        this.doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a5',
        });
        language === "French" ? this.dict = frData : this.dict = enData;
    }

    abstract generate(): void
}