import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {getAirports, onDeleteAirport, onPutAirport} from "../../../Services/DatabaseCommunicator/airport";
import {Box, Button, Stack, Typography} from "@mui/material";
import SingleValueAutoComplete from "../../BaseComponents/singleValueAutoComplete";
import TextField from "../../BaseComponents/textField";

interface Inputs {

    options: string[];

    id: number | undefined;
    airportName: string;

    informationText: string;
}

const AirportModifierForm = () => {
    const {register, setValue, handleSubmit, control, formState: {errors}} = useForm<Inputs>({
        criteriaMode: "all",
        defaultValues: {
            id: undefined,
            airportName: "",
            informationText: ""
        }
    });

    const [optionNames, setOptionNames] = React.useState<string[]>([""]);
    const [options, setOptions] = React.useState<{ [key: string | number]: any }[]>([]);
    const [message, setMessage] = React.useState<string>("");

    const fetchAirports = async () => {
        const airports = await getAirports();
        setOptions(airports);
        const optionNames = airports.map(airport => airport?.name).filter(name => name !== undefined) as string[];
        setOptionNames(optionNames);
    }

    useEffect(() => {
        fetchAirports();
    }, [])


    const onSearchOptionSelected = (optionName: string) => {
        const option = options.find((obj: { [key: string]: any }) => obj.name === optionName);
        setValue("id", undefined)

        if (!option)
            return;

        setValue("id", option.id)
        setValue("airportName", option.name);
        setValue("informationText", option.informationText);
    }

    const onSubmit = async (data: Inputs) => {
        const airportWasPut = await onPutAirport(data.id, data.airportName, data.informationText);
        if (airportWasPut)
            setMessage("Successfully updated data.");
        else
            setMessage("Could not update data.");
        clearFormValues();
        await fetchAirports();
    }

    const onClear = () => {
        setMessage("");
        clearFormValues();
    }

    const clearFormValues = () => {
        setValue("airportName", "");
        setValue("id", undefined);
        setValue("informationText", "");
    }

    const onDelete = async (data: Inputs) => {
        const isDeleted = await onDeleteAirport(data.airportName);
        if (!isDeleted) {
            setMessage("Could not delete data.");
            return;
        }

        clearFormValues();
        setMessage("Successfully deleted data.");
        await fetchAirports();
    }

    return (
        <div className={"AirportModifierForm"}>
            <Box
                style={{
                    alignItems: "center",
                }}
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"flex-start"}>
                            <SingleValueAutoComplete options={optionNames} freeSolo={true} name={"airportName"}
                                                     label={"Airport Name"}
                                                     control={control} register={register} errors={errors}
                                                     required={true}
                                                     onOptionSelected={onSearchOptionSelected}/>
                            <Button variant={"outlined"} onClick={onClear}>Clear</Button>

                        </Stack>

                        <TextField control={control} name={"airportName"} label={"Airport name"} register={register}
                                   errors={errors}/>
                        <TextField control={control} name={"informationText"} label={"Information Text"}
                                   register={register} errors={errors} isRequired={false} multiline={true}/>
                        <Stack direction={"row"} alignItems={"baseline"} spacing={2} justifyContent={"flex-start"}>
                            <Button variant={"outlined"} onClick={handleSubmit(onSubmit)}>Save</Button>
                            <Button variant={"outlined"} onClick={handleSubmit(onDelete)}>Delete</Button>
                        </Stack>
                        <Typography>{message}</Typography>
                    </Stack>
                </form>
            </Box>
        </div>
    )
}

export default AirportModifierForm;