import {Body, get, post, put, remove} from "../Api/Provider";
import {ROOM_URL} from "./constants";


export async function getRooms() {
    let rooms: { [key: string | number]: any }[];
    rooms = await get(ROOM_URL).then(async res => {
        return await res?.json();
    });
    return rooms;
}

export async function onAddRoom(name: string): Promise<boolean> {
    const body = {
        name: name,
    };

    try {
        const res = await post(ROOM_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}


export async function onPutRoom(id: number | undefined, name: string): Promise<boolean> {
    if (id === undefined)
        return onAddRoom(name);

    let body: Body = {
        id: id,
        name: name,
    }

    try {
        const res = await put(ROOM_URL, body);
        if (!res) {
            return false;
        }
        return res.status === 204;
    } catch (e) {
        return false;
    }
}

export async function onDeleteRoom(name: string): Promise<boolean> {

    try {
        const res = await remove(`${ROOM_URL}/name/${name}`);
        if (!res) {
            return false;
        }
        return res.status === 200 || res.status === 204;
    } catch (e) {
        return false;
    }

}