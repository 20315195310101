import React from "react";
import {Button, Grid, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";


const Home = () => {
    return (
        <div>
            <Typography>Create Vouchers</Typography>
            <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs={2}>
                        <Link to="/hotel">
                            <Button variant={"contained"}>Create hotel voucher</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to="/transfer">
                            <Button variant={"contained"}>Create transfer voucher</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to="/carRental">
                            <Button variant={"contained"}>Create car rental voucher</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to="/tour">
                            <Button variant={"contained"}>Create tour voucher</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Stack>


            <Typography>Modify Database</Typography>

            <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs={2}>
                        <Link to="/clientModifier">
                            <Button variant={"contained"}>Modify Clients</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to="/hotelModifier">
                            <Button variant={"contained"}>Modify Hotels</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to="/roomModifier">
                            <Button variant={"contained"}>Modify Rooms</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to="/basisModifier">
                            <Button variant={"contained"}>Modify Basis</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to="/tourModifier">
                            <Button variant={"contained"}>Modify Tours</Button>
                        </Link>
                    </Grid>

                    <Grid item xs={2}>
                        <Link to="/airportModifier">
                            <Button variant={"contained"}>Modify Airports</Button>
                        </Link>
                    </Grid>

                    <Grid item xs={2}>
                        <Link to="/transferServiceProviderModifier">
                            <Button variant={"contained"}>Modify Transfer Service Provider</Button>
                        </Link>
                    </Grid>

                    <Grid item xs={2}>
                        <Link to="/tourServiceProviderModifier">
                            <Button variant={"contained"}>Modify Tour Service Provider</Button>
                        </Link>
                    </Grid>

                    <Grid item xs={2}>
                        <Link to="/carRentalServiceProviderModifier">
                            <Button variant={"contained"}>Modify Car Rental Service Provider</Button>
                        </Link>
                    </Grid>

                </Grid>
            </Stack>
        </div>
    )
}

export default Home;