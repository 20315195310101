import {Body, get, post, put, remove} from "../Api/Provider";
import {TOUR_URL} from "./constants";

export async function getTours() {
    let tour: { [key: string | number]: any }[];
    tour = await get(TOUR_URL).then(async res => {
        return await res?.json();
    });
    return tour;
}

export async function onAddTour(name: string): Promise<boolean> {
    const body = {
        name: name,
    };

    try {
        const res = await post(TOUR_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}

export async function onPutTour(id: number | undefined, name: string): Promise<boolean> {
    if (id === undefined)
        return onAddTour(name);

    let body: Body = {
        id: id,
        name: name,
    }

    try {
        const res = await put(TOUR_URL, body);
        if (!res) {
            return false;
        }
        return res.status === 204;

    } catch (e) {
        return false;
    }
}

export async function onDeleteTour(name: string): Promise<boolean> {
    try {
        const res = await remove(`${TOUR_URL}/name/${name}`);
        if (!res) {
            return false;
        }
        return res.status === 200 || res.status === 204;
    } catch (e) {
        return false;
    }

}