export function handleResponse(response: Response) {
    if (!response.ok)
        handleError(response);
    if (response)
        return response;
}

export function handleError(error: Response) {
    console.log("error code: ", error.status)
    return error;
}
