import {ReactComponentElement} from "react";

interface Props {
    isRender: boolean

    component: ReactComponentElement<any>;
}

const ConditionalComponent = (props: Props) => {
    if (!props.isRender)
        return null;
    return (
        <div>
            {props.component}
        </div>
    )
}

export default ConditionalComponent;