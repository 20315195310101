import {Voucher} from "./voucher";
import {
    formatPhoneNumber,
    generateClientBlock,
    generateContact,
    generateHeaderBlock,
    getDayDifference,
    getStringFromArray,
    initializeAndSetUpDoc
} from "./voucherGeneratorUtils";
import {HotelFormInputs} from "../FormInputs/hotelFormInputs";
import {DATE_FORMAT, FILENAME_VOUCHER, FONT, FONT_BOLD, FONT_STYLE} from "./constantsVoucher";


export type Hotel = {
    name: string,
    address: string,
    neighbourhood: string,
    city: string,
    phone: string
}

export class HotelVoucher extends Voucher {

    inputs: HotelFormInputs;
    hotel: Hotel

    constructor(language: string, inputs: HotelFormInputs, hotel: Hotel) {
        super(language);
        this.inputs = inputs;
        this.hotel = hotel;
    }

    generate(): void {
        this.doc = initializeAndSetUpDoc(this.inputs.voucherNumber, this.inputs.language);
        generateHeaderBlock(this.doc);
        this.generateHotelAndClientBlock();
        this.generateRoomsAndTime();
        generateContact(this.doc, 20, 178);
        this.doc.save(`${FILENAME_VOUCHER} ${this.inputs.voucherNumber}`);
    }

    generateHotelAndClientBlock(): void {
        generateClientBlock(this.doc, this.inputs.clientTitle, this.inputs.clientName, this.inputs.nbPax, 8);
        this.doc.setFont(FONT_BOLD);
        this.doc.text(`${this.dict.hotel}${this.dict.twoPoints}`, 20, 85);
        this.doc.text(this.hotel.name.toUpperCase(), 60, 85);

        let yOffset = 0;

        this.doc.setFont(FONT, FONT_STYLE);
        this.doc.text(`${this.hotel.address}`, 60, 89, {maxWidth: 70});
        if (this.hotel.address.length > 35)
            yOffset += 4;

        if (this.hotel.neighbourhood !== "" && this.hotel.neighbourhood !== null) {
            this.doc.text(`${this.hotel.neighbourhood}, ${this.hotel.city}`, 60, 94 + yOffset, {maxWidth: 70})
            if (this.hotel.neighbourhood.length + this.hotel.city.length > 35)
                yOffset += 4;
        } else {
            this.doc.text(`${this.hotel.city}`, 60, 94 + yOffset, {maxWidth: 70});
        }

        this.doc.setFont(FONT_BOLD);
        this.doc.text(`Tel${this.dict.twoPoints} `, 60, 99 + yOffset);
        this.doc.setFont(FONT, FONT_STYLE);
        this.doc.text(`${formatPhoneNumber(this.hotel.phone)}`, 69, 99 + yOffset);
    }

    generateRoomsAndTime(): void {
        this.doc.rect(15, 110, this.doc.internal.pageSize.getWidth() - 30, 55)
        this.doc.line(55, 110, 55, 165);

        this.doc.setFont(FONT_BOLD);

        this.doc.text(`${this.dict.arrival}${this.dict.twoPoints}\n${this.dict.departure}${this.dict.twoPoints}\n\n${this.inputs.rooms.length > 1 ? this.dict.rooms : this.dict.room}${this.dict.twoPoints}\n\n\n${this.dict.base}${this.dict.twoPoints}\n\n\n${this.dict.nights}${this.dict.twoPoints}`, 20, 116)
        this.doc.text(`${this.inputs.arrivalDate?.format(DATE_FORMAT)}\n${this.inputs.departureDate?.format(DATE_FORMAT)}`, 60, 116);
        this.doc.setFont(FONT, FONT_STYLE);

        this.doc.text(this.formatRooms(), 60, 129.5, {maxWidth: 70})
        this.doc.text(getStringFromArray(this.inputs.basis), 60, 143, {maxWidth: 70})
        this.doc.text(`${getDayDifference(this.inputs.arrivalDate, this.inputs.departureDate)}`, 60, 156.5)
    }

    formatRooms(): string {
        let roomsByCount: Array<{ name: string, count: number }> = [{name: "", count: 0}];

        for (let i = 0; i < this.inputs.rooms.length; ++i) {
            const currentRoom = roomsByCount.find(room => this.inputs.rooms[i] === room.name);
            if (currentRoom === undefined)
                roomsByCount.push({name: this.inputs.rooms[i], count: 1});
            else
                currentRoom.count += 1;
        }

        let res = "";
        roomsByCount = roomsByCount.filter(room => room.count > 0)
        roomsByCount.forEach(room => {
            room.count === 1 ? res += `${room.count} ${room.name} + ` : res += `${room.count} ${room.name}s + `
        })

        return res.slice(0, res.length - 2);
    }

}