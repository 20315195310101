import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#2962ff',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#c2185b',
            dark: '#ba000d',
            contrastText: '#fff',
        },

    },
});

export {theme};
