import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import SingleValueAutoComplete from "../../BaseComponents/singleValueAutoComplete";
import TextField from "../../BaseComponents/textField";
import {
    getTransferServiceProviders,
    onDeleteTransferServiceProvider,
    onPutTransferServiceProvider
} from "../../../Services/DatabaseCommunicator/transferServiceProvider";

interface Inputs {

    options: string[];

    id: number | undefined;
    name: string;

    contactName: string;

    phone: string;
}

const TransferServiceProviderModifierForm = () => {
    const {register, setValue, handleSubmit, control, formState: {errors}} = useForm<Inputs>({
        criteriaMode: "all",
        defaultValues: {
            id: undefined,
            name: "",
            contactName: "",
            phone: ""
        }
    });

    const [optionNames, setOptionNames] = React.useState<string[]>([""]);
    const [options, setOptions] = React.useState<{ [key: string | number]: any }[]>([]);
    const [message, setMessage] = React.useState<string>("");

    const fetchTransferServiceProvider = async () => {
        const transferServiceProvider = await getTransferServiceProviders();
        setOptions(transferServiceProvider);
        const optionNames = transferServiceProvider.map(transfer => transfer?.name).filter(name => name !== undefined) as string[];
        setOptionNames(optionNames);
    }

    useEffect(() => {
        fetchTransferServiceProvider();
    }, [])


    const onSearchOptionSelected = (optionName: string) => {
        const option = options.find((obj: { [key: string]: any }) => obj.name === optionName);
        setValue("id", undefined)

        if (!option)
            return;

        setValue("id", option.id)
        setValue("name", option.name);
        setValue("contactName", option.contactName);
        setValue("phone", option.phone);
    }

    const onSubmit = async (data: Inputs) => {
        const serviceProviderWasPut = await onPutTransferServiceProvider(data.id, data.name, data.contactName, data.phone);
        if (serviceProviderWasPut)
            setMessage("Successfully updated data.");
        else
            setMessage("Could not update data.");
        clearFormValues();
        await fetchTransferServiceProvider();
    }

    const onClear = () => {
        setMessage("");
        clearFormValues();
    }

    const clearFormValues = () => {
        setValue("id", undefined);
        setValue("name", "");
        setValue("contactName", "");
        setValue("phone", "");
    }

    const onDelete = async (data: Inputs) => {
        const isDeleted = await onDeleteTransferServiceProvider(data.name);
        if (!isDeleted) {
            setMessage("Could not delete data.");
            return;
        }

        clearFormValues();
        setMessage("Successfully deleted data.");
        await fetchTransferServiceProvider();
    }

    return (
        <div className={"TransferServiceProviderModifierForm"}>
            <Box
                style={{
                    alignItems: "center",
                }}
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"flex-start"}>
                            <SingleValueAutoComplete options={optionNames} freeSolo={true} name={"name"} label={"Name"}
                                                     control={control} register={register} errors={errors}
                                                     required={true}
                                                     onOptionSelected={onSearchOptionSelected}/>
                            <Button variant={"outlined"} onClick={onClear}>Clear</Button>
                        </Stack>

                        <TextField control={control} name={"name"} label={"Name"} register={register} errors={errors}/>
                        <TextField control={control} name={"contactName"} label={"Contact Name"} register={register}
                                   errors={errors} isRequired={false}/>
                        <TextField control={control} name={"phone"} label={"Phone Number"} register={register}
                                   errors={errors}
                                   rules={{
                                       pattern: {
                                           value: /^\+90\s?\d{3}\s?\d{3}\s?\d{2}\s?\d{2}$/,
                                           message: "Invalid number format."
                                       }
                                   }}/>
                        <Stack direction={"row"} alignItems={"baseline"} spacing={2} justifyContent={"flex-start"}>
                            <Button variant={"outlined"} onClick={handleSubmit(onSubmit)}>Save</Button>
                            <Button variant={"outlined"} onClick={handleSubmit(onDelete)}>Delete</Button>
                        </Stack>
                        <Typography>{message}</Typography>
                    </Stack>
                </form>
            </Box>
        </div>
    )
}

export default TransferServiceProviderModifierForm;