import {Box, Button, Stack} from "@mui/material";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import TickBoxGroup from "../../BaseComponents/tickBoxGroup";
import NumberTextField from "../../BaseComponents/numberTextField";
import SingleValueAutoComplete from "../../BaseComponents/singleValueAutoComplete";
import DatabaseOptionsAutoComplete from "../../BaseComponents/databaseOptionsAutoComplete";
import {TourFormInputs} from "../../FormInputs/tourFormInputs";
import DatePicker from "../../BaseComponents/datePicker";
import dayjs from "dayjs";
import TimePickerForm from "../../BaseComponents/timePicker";
import {Voucher} from "../../VoucherGeneration/voucher";
import {TourVoucher} from "../../VoucherGeneration/tourVoucher";
import TextField from "../../BaseComponents/textField";
import {getClientNames, postClientIfNew} from "../../../Services/DatabaseCommunicator/client";
import {ServiceProvider} from "../../VoucherGeneration/voucherGeneratorUtils";
import {getTourServiceProvider} from "../../../Services/DatabaseCommunicator/tourServiceProvider";


const TourVoucherForm = () => {

    const {register, handleSubmit, control, formState: {errors}} = useForm<TourFormInputs>({
        criteriaMode: "all",
        defaultValues: {
            language: "French",
            clientTitle: "Mr",
            voucherNumber: "0",
            nbPax: "0",
            tourServiceProvider: ""
        }
    });

    const [clientNames, setClientNames] = React.useState<string[]>([]);

    useEffect(() => {
        const updateClientNames = async () => {
            setClientNames(await getClientNames());
        }
        updateClientNames();
    }, [])

    const onSubmit = async (data: TourFormInputs) => {

        const serviceProvider: ServiceProvider = await getTourServiceProvider(data.tourServiceProvider);

        let voucher: Voucher = new TourVoucher(data.language, data, serviceProvider);

        voucher.generate();

        const newClientIsPosted = await postClientIfNew(data.clientName, clientNames);
        if (newClientIsPosted)
            setClientNames(clientNames => [...clientNames, data.clientName]);
    }

    const handleError = () => {
        console.log("errors: ", errors);
    }

    return (
        <div className={"tourForm"}>
            <Box
                style={{
                    alignItems: "center",
                    justifyContent: "center"
                }}
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} onError={handleError}>
                    <h2>Tour Voucher</h2>
                    <Stack direction={"row"} spacing={5} alignItems={"start"} justifyContent={"center"}>
                        <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                            <TickBoxGroup options={["French", "English"]} name={"language"} control={control}
                                          register={register} errors={errors}/>
                            <DatabaseOptionsAutoComplete query={"tourServiceProvider"} freeSolo={false}
                                                         name={"tourServiceProvider"} label={"Service Provider"}
                                                         control={control} register={register} errors={errors}/>
                            <NumberTextField name={"voucherNumber"} label={"Voucher Number"} register={register}
                                             errors={errors}/>
                            <Stack direction={"row"} alignItems={"baseline"} justifyContent={"flex-start"}>
                                <Stack direction={"column"} alignItems={"baseline"} justifyContent={"flex-start"}>
                                    <SingleValueAutoComplete options={["Mr", "Mrs"]} name={"clientTitle"}
                                                             label={"Client Title"} control={control}
                                                             register={register} errors={errors}/>
                                </Stack>
                                <SingleValueAutoComplete options={clientNames} label={"Client Name"} freeSolo={true}
                                                         name={"clientName"} control={control} register={register}
                                                         errors={errors}/>
                            </Stack>
                            <NumberTextField name={"nbPax"} label={"Pax Number"} register={register} errors={errors}/>
                            <DatabaseOptionsAutoComplete query={"Tour"} label={"Tour 1"} name={"tour1"}
                                                         control={control} register={register} errors={errors}
                                                         required={false}/>
                            <DatePicker name={"date1"} defaultValue={dayjs()} label={"Date 1"} control={control}
                                        register={register} errors={errors}/>
                            <TimePickerForm name={"date1"} label={"Time 1"} defaultValue={dayjs()} control={control}
                                            register={register} errors={errors}/>


                        </Stack>
                        <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>

                            <DatabaseOptionsAutoComplete query={"Tour"} label={"Tour 2"} name={"tour2"}
                                                         control={control} register={register} errors={errors}
                                                         required={false}/>
                            <DatePicker name={"date2"} defaultValue={dayjs()} label={"Date 2"} control={control}
                                        register={register} errors={errors}/>
                            <TimePickerForm name={"date2"} label={"Time 2"} defaultValue={dayjs()} control={control}
                                            register={register} errors={errors}/>

                            <DatabaseOptionsAutoComplete query={"Tour"} label={"Tour 3"} name={"tour3"}
                                                         control={control} register={register} errors={errors}
                                                         required={false}/>
                            <DatePicker name={"date3"} defaultValue={dayjs()} label={"Date 3"} control={control}
                                        register={register} errors={errors}/>
                            <TimePickerForm name={"date3"} label={"Time 3"} defaultValue={dayjs()} control={control}
                                            register={register} errors={errors}/>

                            <DatabaseOptionsAutoComplete query={"Tour"} label={"Tour 4"} name={"tour4"}
                                                         control={control} register={register} errors={errors}
                                                         required={false}/>
                            <DatePicker name={"date4"} defaultValue={dayjs()} label={"Date 4"} control={control}
                                        register={register} errors={errors}/>
                            <TimePickerForm name={"date4"} label={"Time 4"} defaultValue={dayjs()} control={control}
                                            register={register} errors={errors}/>

                            <TextField control={control} name={"suggestions"} label={"Suggestions"} register={register}
                                       errors={errors} multiline={true}/>
                        </Stack>
                    </Stack>
                    <p/>
                    <Button variant={"outlined"} type="submit">Generate</Button>

                </form>


            </Box>
        </div>
    )

}

export default TourVoucherForm;