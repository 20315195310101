import React, {useEffect} from "react";
import {Box, Button, Stack,} from "@mui/material";
import dayjs from "dayjs";
import {useForm} from "react-hook-form";
import NumberTextField from "../../BaseComponents/numberTextField";
import DatabaseOptionsAutoComplete from "../../BaseComponents/databaseOptionsAutoComplete";
import SingleValueAutoComplete from "../../BaseComponents/singleValueAutoComplete";
import TickBoxGroup from "../../BaseComponents/tickBoxGroup";
import DatePicker from "../../BaseComponents/datePicker";
import {Voucher} from "../../VoucherGeneration/voucher";
import {Hotel, HotelVoucher} from "../../VoucherGeneration/hotelVoucher";
import {HotelFormInputs} from "../../FormInputs/hotelFormInputs";
import {getClientNames, postClientIfNew} from "../../../Services/DatabaseCommunicator/client";
import {getHotel} from "../../../Services/DatabaseCommunicator/hotel";


const HotelVoucherForm = () => {
    const {register, handleSubmit, control, watch, setValue, formState: {errors}} = useForm<HotelFormInputs>({
        criteriaMode: "all",
        defaultValues: {
            language: "French",
            clientTitle: "Mr",
            voucherNumber: "0",
            nbPax: "0"
        }
    });

    const [clientNames, setClientNames] = React.useState<string[]>([""]);

    useEffect(() => {
        const updateClientNames = async () => {
            setClientNames(await getClientNames());
        }
        updateClientNames();
    }, [])

    //if arrivaldate is omdified, departure date shoudl be set the same
    useEffect(() => {
        if (watch().arrivalDate) {

            if (watch().arrivalDate?.isAfter(watch().departureDate))
                setValue("departureDate", watch().arrivalDate);
        }
    }, [watch().arrivalDate, setValue]);

    //if arrivaldate is omdified, departure date shoudl be set the same
    useEffect(() => {
        if (watch().departureDate) {
            if (watch().departureDate?.isBefore(watch().arrivalDate))
                setValue("arrivalDate", watch().departureDate);
        }
    }, [watch().departureDate, setValue]);
    const onSubmit = async (data: HotelFormInputs) => {

        let hotel: Hotel = await getHotel(data.hotelName);
        let voucher: Voucher = new HotelVoucher(data.language, data, hotel);
        voucher.generate();

        const newClientIsPosted = await postClientIfNew(data.clientName, clientNames);
        if (newClientIsPosted)
            setClientNames(clientNames => [...clientNames, data.clientName]);
    }

    const handleError = () => {
        console.log("errors: ", errors);
    }

    return (
        <div className={"VoucherLayout"}>
            <Box
                style={{
                    alignItems: "center",
                }}
                sx={{
                    '& .MuiTextField-root': {m: 1, width: '25ch'},
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)} onError={handleError}>
                    <Stack direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <h2>Hotel Voucher</h2>
                        <TickBoxGroup options={["French", "English"]} name={"language"} control={control}
                                      register={register} errors={errors}/>
                        <NumberTextField name={"voucherNumber"} label={"Voucher Number"} register={register}
                                         errors={errors}/>
                        <Stack direction={"row"} alignItems={"baseline"} justifyContent={"flex-start"}>
                            <Stack direction={"column"} alignItems={"baseline"} justifyContent={"flex-start"}>
                                <SingleValueAutoComplete options={["Mr", "Mrs"]} name={"clientTitle"}
                                                         label={"Client Title"} control={control} register={register}
                                                         errors={errors}/>
                            </Stack>
                            <SingleValueAutoComplete options={clientNames} label={"Client Name"} freeSolo={true}
                                                     name={"clientName"} control={control} register={register}
                                                     errors={errors}/>
                        </Stack>
                        <NumberTextField name={"nbPax"} label={"Pax Number"} register={register} errors={errors}/>
                        <DatabaseOptionsAutoComplete query={"Hotel"} freeSolo={false} name={"hotelName"}
                                                     label={"Hotel Name"} control={control} register={register}
                                                     errors={errors}/>
                        <DatePicker name={"arrivalDate"} defaultValue={dayjs()} label={"Arrival Date"} control={control}
                                    register={register} errors={errors}/>
                        <DatePicker name={"departureDate"} label={"Departure Date"} defaultValue={dayjs()} rules={{
                            validate: () => {
                                return (watch().departureDate?.isAfter(watch().arrivalDate) || watch().departureDate?.isSame(watch().arrivalDate)) || "arrival date must be after departure date.";
                            }
                        }} control={control} register={register} errors={errors}/>

                        <DatabaseOptionsAutoComplete query={"Room"} name={"rooms"} freeSolo={false} multiple={true}
                                                     label={"Rooms"} control={control} register={register}
                                                     errors={errors}/>
                        <DatabaseOptionsAutoComplete query={"Basis"} name={"basis"} freeSolo={false} multiple={true}
                                                     label={"Basis"} control={control} register={register}
                                                     errors={errors}/>

                        <Button variant={"outlined"} type="submit">Generate</Button>
                    </Stack>
                </form>
            </Box>
        </div>
    )
}

export default HotelVoucherForm;