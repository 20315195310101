import {Voucher} from "./voucher";
import {
    generateClientBlock,
    generateContact,
    generateHeaderBlock,
    generateServiceProvider,
    initializeAndSetUpDoc,
    ServiceProvider
} from "./voucherGeneratorUtils";
import {TransferFormInputs} from "../FormInputs/transferFormInputs";
import {DATE_FORMAT, FILENAME_VOUCHER, FONT, FONT_BOLD, TIME_FORMAT} from "./constantsVoucher";

export class DepartureTransferVoucher extends Voucher {

    inputs: TransferFormInputs;

    serviceProvider: ServiceProvider;

    constructor(language: string, inputs: TransferFormInputs, serviceProvider: ServiceProvider) {
        super(language);
        this.inputs = inputs;
        this.serviceProvider = serviceProvider;
    }

    generate(): void {
        this.doc = initializeAndSetUpDoc(this.inputs.voucherNumber, this.inputs.language);
        generateHeaderBlock(this.doc);
        generateClientBlock(this.doc, this.inputs.clientTitle, this.inputs.clientName, this.inputs.nbPax, 7);
        generateServiceProvider(this.doc, this.serviceProvider, 85)
        this.addDepartureTransferServiceBlock();
        generateContact(this.doc, 20, 192, false);
        this.doc.save(`${FILENAME_VOUCHER} ${this.inputs.voucherNumber}`);
    }

    addDepartureTransferServiceBlock() {
        this.doc.rect(15, 110, this.doc.internal.pageSize.getWidth() - 30, 71)
        this.doc.line(55, 110, 55, 181);

        this.addDepartureTransferLeftColumn();
        this.addDepartureTransferRightColumn();
    }

    addDepartureTransferLeftColumn() {
        this.doc.setFont(FONT_BOLD);
        this.doc.text(`${this.dict.services}${this.dict.twoPoints}\n\n${this.dict.date}${this.dict.twoPoints}\n\n${this.dict.from}${this.dict.twoPoints}\n${this.dict.at}${this.dict.twoPoints}\n\nA l'aéroport de${this.dict.twoPoints}\nAu vol:\n${this.dict.at}${this.dict.twoPoints}`, 19, 118);
    }

    addDepartureTransferRightColumn() {
        this.doc.text(`${this.dict.transfer}\n\n${this.inputs.date?.format(DATE_FORMAT)}\n\n${this.inputs.hotelName}`, 60, 118);
        this.doc.setTextColor(0, 0, 255);
        this.doc.text(`${this.inputs.departureTime?.format(TIME_FORMAT)}`, 60, 141)
        this.doc.setTextColor(0, 0, 0);

        this.doc.setFont(FONT);

        this.doc.text(`${this.inputs.airport}\n${this.inputs.flightNumber}\n${this.inputs.flightTime?.format(TIME_FORMAT)}`, 60, 149)
        this.doc.setTextColor(0, 0, 255);
        this.doc.setFont(FONT_BOLD);

        if (this.inputs.suggestions !== "") {
            this.doc.text(this.inputs.suggestions, 60, 167, {maxWidth: 70})
        } else {
            this.doc.text("Votre chauffeur vous attendra devant l'hôtel à l'heure convenue.", 60, 167, {maxWidth: 70})
        }
        this.doc.setTextColor(0, 0, 0);

    }
}