import {Voucher} from "./voucher";
import {
    addAirportImage,
    generateClientBlock,
    generateContact,
    generateHeaderBlock,
    generateServiceProvider,
    initializeAndSetUpDoc,
    ServiceProvider
} from "./voucherGeneratorUtils";
import {TransferFormInputs} from "../FormInputs/transferFormInputs";
import {DATE_FORMAT, FILENAME_VOUCHER, FONT, FONT_BOLD, TIME_FORMAT} from "./constantsVoucher";

export type Airport = {
    name: string,
    informationText: string,
}

export class ArrivalTransferVoucher extends Voucher {

    inputs: TransferFormInputs;
    airport: Airport;

    serviceProvider: ServiceProvider

    constructor(language: string, inputs: TransferFormInputs, airport: Airport, serviceProvider: ServiceProvider) {
        super(language);
        this.inputs = inputs;
        this.airport = airport;
        this.serviceProvider = serviceProvider;
    }

    generate(): void {
        this.doc = initializeAndSetUpDoc(this.inputs.voucherNumber, this.inputs.language);
        generateHeaderBlock(this.doc);
        generateClientBlock(this.doc, this.inputs.clientTitle, this.inputs.clientName, this.inputs.nbPax, 7);
        generateServiceProvider(this.doc, this.serviceProvider, 85);
        this.addArrivalTransferServiceBlock();
        this.doc.save(`${FILENAME_VOUCHER} ${this.inputs.voucherNumber}`);
    }

    addArrivalTransferServiceBlock() {
        this.doc.rect(15, 110, this.doc.internal.pageSize.getWidth() - 30, 85)
        this.doc.line(55, 110, 55, 194);

        this.doc.setFont(FONT_BOLD);
        this.addTransferArrivalServiceLeftColumn();
        this.addTransferArrivalServiceRightColumn();
        generateContact(this.doc, 20, 200, false);
        addAirportImage(this.doc, this.airport.name);
    }

    addTransferArrivalServiceLeftColumn() {
        this.doc.text(`${this.dict.date}${this.dict.twoPoints}\n\n${this.dict.from}${this.dict.twoPoints}\n${this.dict.ofFlight}${this.dict.twoPoints}\n${this.dict.arrivingAt}${this.dict.twoPoints}\n\n${this.dict.at}${this.dict.twoPoints}`, 19, 117);

        this.doc.setTextColor(255, 0, 0);

        this.doc.text(`${this.dict.beCareful}`, 17, 153);
        this.doc.setTextColor(0, 0, 0);
        this.doc.text(this.airport.informationText, 17, 158, {maxWidth: 37});

        this.doc.setTextColor(0, 0, 0);
    }

    addTransferArrivalServiceRightColumn() {
        this.doc.text(`${this.inputs.date?.format(DATE_FORMAT)}`, 60, 117);
        this.doc.setFont(FONT);

        this.doc.text(`${this.airport.name}\n${this.inputs.flightNumber}\n`, 60, 125, {maxWidth: 70})
        this.doc.setFont(FONT_BOLD);

        this.doc.text(`${this.inputs.flightTime?.format(TIME_FORMAT)}\n\n${this.inputs.hotelName}`, 60, 134);
        this.doc.setFont(FONT);

        this.doc.text("Si vous ne parvenez pas à localiser votre chauffeur, ne quittez pas l'aéroport. Appelez l'un des numéros ci-dessous et nous vous guiderons jusqu'au point de rencontre.", 60, 161, {maxWidth: 70})

    }
}