import {Body, get, post, put, remove} from "../Api/Provider";
import {HOTEL_URL} from "./constants";


export async function getHotelNames() {
    let hotelNames: Array<string>;
    [hotelNames] = await Promise.all([get(HOTEL_URL).then(async res => {
        let hotelNames: Array<string> = [];
        if (res?.ok) {
            const data = await res.json();
            await data.map((element: { name: string }) => hotelNames.push(element.name));
            //res.json().then(async values => await Promise.all(values.map(async (val: { name: string; }) => hotelNames.push(val.name))));

        }
        return hotelNames;
    })]);
    return hotelNames;
}


export async function getHotels() {
    let hotels: { [key: string | number]: any }[];
    hotels = await get(HOTEL_URL).then(async res => {
        return await res?.json();
    });
    return hotels;
}

export async function getHotel(name: string) {
    return await get(`${HOTEL_URL}/name/${name}`).then(async res => {
        return await res?.json()
    })
}

export async function onAddHotel(name: string, address: string, neighbourhood: string, city: string, phoneNumber: string): Promise<boolean> {
    const body = {
        name: name,
        address: address,
        neighbourhood: neighbourhood,
        city: city,
        phone: phoneNumber
    };

    try {
        const res = await post(HOTEL_URL, body);
        if (!res) {
            return false;
        }
        const data = await res.json();
        console.log("data.errors: ", data.errors);
        return !data.errors;
    } catch (e) {
        return false;
    }
}


export async function onPutHotel(id: number | undefined, name: string, address: string, neighbourhood: string, city: string, phoneNumber: string): Promise<boolean> {
    if (id === undefined)
        return onAddHotel(name, address, neighbourhood, city, phoneNumber);

    let body: Body = {
        id: id,
        name: name,
        address: address,
        neighbourhood: neighbourhood,
        city: city,
        phone: phoneNumber
    }

    try {
        const res = await put(HOTEL_URL, body);
        if (!res) {
            return false;
        }
        return res.status === 204;

    } catch (e) {
        return false;
    }
}

export async function onDeleteHotel(name: string): Promise<boolean> {

    try {
        const res = await remove(`${HOTEL_URL}/name/${name}`);
        if (!res) {
            return false;
        }
        return res.status === 200 || res.status === 204;
    } catch (e) {
        return false;
    }

}